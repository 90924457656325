import { viewClassName } from '@angular/compiler';
import { Component, OnInit, AfterViewChecked, ElementRef, Input, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import { PhotoService } from '../../app/service/photoservice';
import * as $ from 'jquery';
import 'magnific-popup';

// import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
/* vjs-player.component.css */
// import '~video.js/dist/video-js.css';

@Component({
    selector: 'app-restaurant',
    templateUrl: './restaurant.component.html'
    // ,styleUrls: ['./vjs-player.component.css']
    ,encapsulation: ViewEncapsulation.None
})
export class RestaurantComponent implements OnInit, AfterViewInit, AfterViewChecked {
    // @ViewChild('img') imgElement:ElementRef; 
    // @ViewChild('video') videoElement:ElementRef; 

    @ViewChild('img') imgElement: ElementRef
    @ViewChild('img2') imgElement2: ElementRef
    @ViewChild('img3') imgElement3: ElementRef
    @ViewChild('img4') imgElement4: ElementRef
    // @ViewChild('img5') imgElement5: ElementRef
    // @ViewChild('img6') imgElement6: ElementRef
    
    N1DRoomsImages: any[];
    N1DImagesPath: string  = 'assets/data/n1DRooms.json';

    N1SRoomsImages: any[];
    N1SImagesPath: string  = 'assets/data/n1SRooms.json';

    N2RoomsImages: any[];
    N2ImagesPath: string  = 'assets/data/n2Rooms.json';

    allRoomsImages: any[];
    allImagesPath: string  = 'assets/data/allRooms.json';

    @ViewChild('restaurantVid', {static: true}) restaurantVid: ElementRef;
    @ViewChild('gelatoVid', {static: true}) gelatoVid: ElementRef;

   
    @Input() options: {
        fluid: boolean,
        aspectRatio: string,
        autoplay: boolean,
        sources: {
            src: string,
            type: string,
        }[],
    };
    gelatoVidPlayer: videojs.Player;
    restaurantVidPlayer: videojs.Player;

    constructor(private elementRef: ElementRef, private photoService: PhotoService) { }
 

    ngOnInit() {
        // this.photoService.getImages(this.N1DImagesPath).then(images => {
        //     this.N1DRoomsImages = images
        //   })

        // this.photoService.getImages(this.N1SImagesPath).then(images => {
        //     this.N1SRoomsImages = images
        //   })

        // this.photoService.getImages(this.N2ImagesPath).then(images => {
        //   this.N2RoomsImages = images
        // })

        // this.photoService.getImages(this.allImagesPath).then(images => {
        //     this.allRoomsImages = images
        //   })
        
        
    }

    ngAfterViewInit (){
        // instantiate Video.js
        this.gelatoVidPlayer = videojs(this.gelatoVid.nativeElement, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        });

        this.restaurantVidPlayer = videojs(this.restaurantVid.nativeElement, this.options, function onPlayerReady() {
            console.log('onPlayerReady', this);
        });
    }

    ngOnDestroy() {
        // destroy player
        if (this.gelatoVidPlayer) {
            this.gelatoVidPlayer.dispose();
        }

        if (this.restaurantVidPlayer) {
            this.restaurantVidPlayer.dispose();
        }
    }
    
    ngAfterViewChecked (): void {
   
        if (this.imgElement) {
           $(this.imgElement.nativeElement).magnificPopup({ 
                type: 'inline',
                fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom'
            });
        }

        if (this.imgElement2) {
            $(this.imgElement2.nativeElement).magnificPopup({ 
                 type: 'inline',
                 fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                 fixedBgPos: true,
                 overflowY: 'auto',
                 closeBtnInside: true,
                 preloader: false,
                 midClick: true,
                 removalDelay: 300,
                 mainClass: 'my-mfp-slide-bottom'
             });
         }
         if (this.imgElement3) {
            $(this.imgElement3.nativeElement).magnificPopup({ 
                 type: 'inline',
                 fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                 fixedBgPos: true,
                 overflowY: 'auto',
                 closeBtnInside: true,
                 preloader: false,
                 midClick: true,
                 removalDelay: 300,
                 mainClass: 'my-mfp-slide-bottom'
             });
         }
         if (this.imgElement4) {
            $(this.imgElement4.nativeElement).magnificPopup({ 
                 type: 'inline',
                 fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                 fixedBgPos: true,
                 overflowY: 'auto',
                 closeBtnInside: true,
                 preloader: false,
                 midClick: true,
                 removalDelay: 300,
                 mainClass: 'my-mfp-slide-bottom'
             });
         }
    }

    OnHideGelatoPopup(){
        if (this.gelatoVidPlayer) {
            this.gelatoVidPlayer.pause();
        }
    }
}

