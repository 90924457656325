
<!-- About -->
<div id="about" class="counter">
    <div class="container">
        <div class="row">
            <div class="col"> <!--col-lg-7 col-xl-6-->
                <div>
                    <div class="col-lg-12 text-center">
                        <div class="section-title">ABOUT US</div>
                        <h4>We're Passionate To Serve Delicious Italian Foods And Comfortable Accomodation</h4>
                    </div> <!-- end of col -->
                    <br>

                    <!-- <div class="section-title">ABOUT US</div>
                    <h4>We're Passionate To Serve Delicious Italian Foods And Comfortable Accomodation</h4> -->
                    <p class="testimonial-text text-justify">
                        Angelina Beach Resort & Italian Restaurant (Malapascua) offers spacious rooms with a balcony offering views of the sea and of the garden. 
                        It provides free Wi-Fi access throughout the property. 
                    </p>
                    <p class="testimonial-text text-justify">
                        Fitted with parquet flooring, modern air-conditioned rooms are furnished with a wardrobe and a sofa area. For convenience, a personal safe and a fridge are provided. 
                        Private bathrooms come with a hairdryer, hot/cold shower.
                    </p>
                    <p class="testimonial-text text-justify">
                        Pets are welcome both in the rooms and the restaurant.
                    </p>
                    <p class="testimonial-text text-justify">
                        Angelina Beach provides currency exchange and laundry services on request. Guests may also enjoy a pampering massage on site, 
                        or relax at the garden terrace. Airport transfer can also be arranged at an extra charge.
                    </p>
                    <p class="testimonial-text text-justify">
                        The in-house Angelina Restaurant serves fine Italian cuisine, while a range of drinks can be ordered at the bar. 
                        Guests can also enjoy meals in private with room service. 
                    </p>
                    <p class="testimonial-text text-justify">
                        Angela owns and runs the kitchen herself. Serving real Italian home cooking, she offers the simple and original recipes from her homeland, Emilia Romagna, a world famous food capital. 
                        The pizzeria serves delicious pizza, made according to the best Italian traditions. The ingredients are imported from Italy and are served with a great choice of wines from the Veneto valleys.
                    </p>
                    <p class="testimonial-text text-justify">
                        Angelina offers a complete menu that includes appetizers and homemade pasta. The meats include our own home made sausages, fish that we love to grill, great risottos, fantastic pizzas and a great wine selection of white and red wines. 
                        We use only high quality ingredients. The fish is fresh from the fishermen of the island and cooked in the most flavorful and best Mediterranean traditions.
                    </p>
                    <p class="testimonial-text text-justify">
                        We have tables directly on the beach and a comfortable indoor dining area. We are open all year round Lunchtime up to 2PM and from 4PM to 10PM.
                    </p>
                    <p class="testimonial-text text-justify">
                        The property is approximately a 3-hour drive from Mactan Cebu International Airport.
                    </p>
                    <p class="testimonial-text text-justify">
                        Angelina Beach Resort & Italian Restaurant (Malapascua) has been welcoming guests since Mar 8, 2013.
                    </p>
                    <!-- <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">Everything we recommend has direct positive impact</div>
                        </li>
                        <li class="media">
                            <i class="fas fa-square"></i>
                            <div class="media-body">You will become an important partner of our company</div>
                        </li>
                    </ul> -->

                    <!-- Counter -->
                    <!-- <div id="counter">
                        <div class="cell">
                            <div class="counter-value number-count" data-count="231">1</div>
                            <div class="counter-info">Happy<br>Users</div>
                        </div>
                        <div class="cell">
                            <div class="counter-value number-count" data-count="121">1</div>
                            <div class="counter-info">Issues<br>Solved</div>
                        </div>
                        <div class="cell">
                            <div class="counter-value number-count" data-count="159">1</div>
                            <div class="counter-info">Good<br>Reviews</div>
                        </div>
                    </div> -->
                    <!-- end of counter -->

                </div> <!-- end of text-container -->      
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <div class="col-sm-6">
                <div class="image-container">
                    <img class="img-fluid" src="assets/images/aboutUs1.jpg" alt="alternative">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
            <div class="col-sm-6">
                <div class="image-container">
                    <img class="img-fluid" src="assets/images/aboutUs2.jpg" alt="alternative">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
        </div>
    </div> <!-- end of container -->
</div> <!-- end of counter -->
<!-- end of about -->