<!-- Restaurant -->
<div id="projects" class="filter">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="section-title">RESTAURANT</div>
                <h4>Foods and Beverages</h4>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            
            <div class="col-lg-7">
                <!-- <app-vjs-player [options]="{ autoplay: true, controls: true, sources: [{ src: 'assets/videos/restaurant-vid.mp4', type: 'video/mp4' }]}">
                </app-vjs-player> -->
                <div class="float-right"> 
                    <video #restaurantVid id="vid-restaurant" class="video-js" style="height: 21rem;width: 32rem;" data-setup='{"controls": true, "autoplay": true, "preload": "auto"}'>
                        <source src="assets/videos/restaurant-vid.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
            <div class="col-lg-5" >
                <div class="pt-2 text-center">
                    <a style="text-decoration: none;" href="assets/images/foodsDrinks/restaurant-menu.pdf" target="_blank">
                        <h6 class="menu-link">>>CLICK TO VIEW MENU<<</h6></a>
                    <span>OR</span>
                    <h6 style="margin-top:6px;font-size: larger;">Scan Code Below</h6>
                    <img src="assets/images/QRCode_Final.jpg" style="height: 15rem;">
                </div>
                
            </div>

            
            
        </div>
        
        <div class="row" style="margin-top:2rem;">
            <div class="col">
                <p class="testimonial-text text-justify">
                    Angelina offers a complete menu that includes appetizers and homemade pasta. 
                    The meats include our own home made sausages, fish that we love to grill, great risottos, fantastic pizzas and a great wine selection of white and red wines. 
                    We use only high quality ingredients. The fish is fresh from the fishermen of the island and cooked in the most flavorful and best Mediterranean traditions.
                </p>
                <!-- <p class="testimonial-text">We are serving real Italian home cooking and offering the simple and original recipe from Emilia-Romagna, a world famous food capital.
                    The pizzeria serves delicious pizza made according to the best tradition.
                    The ingredients are imported from Italy and are served with a great choice of wines.
                    We have tables directly on the beach and a comfortable indoor dining area.
                </p> -->
            </div>
            
        </div>
        <div class="row">
            <div class="col-lg-12">
                <!-- Filter -->
                <!--<div class="button-group filters-button-group">
                    <a class="button is-checked" data-filter="*"><span>SHOW ALL</span></a>
                    <a class="button" data-filter=".design"><span>DESIGN</span></a>
                    <a class="button" data-filter=".development"><span>DEVELOPMENT</span></a>
                    <a class="button" data-filter=".marketing"><span>MARKETING</span></a>
                    <a class="button" data-filter=".seo"><span>SEO</span></a>
                </div>--> <!-- end of button group -->
                <div class="grid d-flex justify-content-center">
                    <div class="element-item restaurant-element">
                        <div style="text-align: center;">
                            <h6><i>Pizzas</i></h6>
                        </div>
                        <div  style="padding-top: 1rem;">
                            <a #img class="popup-with-move-anim" href="#project-1">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/foodsDrinks/pizza/pizzas_col.jpg" alt="alternative"></a>
                                <!-- <img src="assets/images/rooms/N1_D/roomDeluxedN1_5x5.jpg" alt="alternative"></a> -->
                        </div>
                    </div>
                    
                    <div class="element-item restaurant-element">
                        <div style="text-align: center;">
                            <h6><i>Pastas</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img2 class="popup-with-move-anim" href="#project-2">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/foodsDrinks/pasta/pasta_col.jpg" alt="alternative"></a>
                                <!-- <img src="assets/images/rooms/N2/roomDeluxeStudio_5x5.jpg" alt="alternative"></a> -->
                        </div>
                    </div>

                    <div class="element-item restaurant-element">
                        <div style="text-align: center;">
                            <h6><i>Gelatos</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img3 class="popup-with-move-anim" href="#project-3">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/foodsDrinks/gelato/gelato_5x5.jpg" alt="alternative"></a>
                                <!-- <img src="assets/images/rooms/N1_S/roomStandard_5x5.jpg" alt="alternative"></a> -->
                        </div>
                    </div>

                    <div class="element-item restaurant-element">
                        <div style="text-align: center;">
                            <h6><i>Imported Italian Wines</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img4 class="popup-with-move-anim" href="#project-4">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/foodsDrinks/drink/drinks_col.jpg" alt="alternative"></a>
                        </div>
                    </div>
                    <!-- 
                    
                    <div class="element-item design marketing">
                        <a class="popup-with-move-anim" href="#project-7"><div class="element-item-overlay"><span>Casual Wear</span></div><img src="assets/images/project-7.jpg" alt="alternative"></a>
                    </div>
                    <div class="element-item design marketing">
                        <a class="popup-with-move-anim" href="#project-8"><div class="element-item-overlay"><span>Zazoo Apps</span></div><img src="assets/images/project-8.jpg" alt="alternative"></a>
                    </div> -->
                </div> <!-- end of grid -->
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->

        <!-- Start Gallery -->
        <!-- <div class="row" style="margin-top:3rem;">
            <div class="col-lg-12">
                <p-galleria [(value)]="allRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="true" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="false" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria>
            </div>
        </div> -->
        <!-- End of Gallery -->

    </div> <!-- end of container -->
</div> <!-- end of filter -->
<!-- end of projects -->


<!-- Project Lightboxes -->
    <!-- Lightbox -->
    <div id="project-1" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <img class="img-fluid" src="assets/images/foodsDrinks/pizza/3.jpg" alt="alternative">
                <!-- <p-galleria [(value)]="N1DRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="false" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria> -->
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Pizza</h3>
                <hr class="line-heading">
                <h6>Pizza</h6>
                <p>
                    Our pizza cooked in a wood fired oven daily from 5:00pm to 9:00 pm.
                    Available sizes:
                    <!-- <li>Small = 9 inches</li> -->
                    <li>Regular = 12 inches</li>
                    <li>Family = 18 inches</li>
                </p>

                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <div id="project-2" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <img class="img-fluid" src="assets/images/foodsDrinks/pasta/1.jpg" alt="alternative">
                <!-- <p-galleria [(value)]="N2RoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="false" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria> -->
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Pasta</h3>
                <hr class="line-heading">
                <h6>Pasta</h6>
                <!-- <p>This room is spacious that has 42 sq.meters.
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <li>TV</li>
                    <li>Fridge</li>
                    <li>Microwave</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (once a week)</li>
                    <li>Laundry (twice a week)</li>
                </p> -->

                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <div id="project-3" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button" (click)="OnHideGelatoPopup()">×</button>
            <div class="col-lg-10">
                <video #gelatoVid id="vid-gelato" class="video-js" style="height: 34rem;width: 60rem;" data-setup='{"fluid": true, "controls": true, "autoplay": false, "preload": "auto"}'>
                    <source src="assets/videos/gelato-vid.mp4" type="video/mp4">
                </video>
                <!-- <img class="img-fluid" src="assets/images/foodsDrinks/gelato/1.jpg" alt="alternative"> -->
                <!-- <p-galleria [(value)]="N1SRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="false" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria> -->
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Gelato</h3>
                <hr class="line-heading">
                <h6>Homemade Gelato</h6>
                <!-- <p>
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <li>TV</li>
                    <li>Fridge</li>
                    <li>Microwave</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (once a week)</li>
                    <li>Laundry (twice a week)</li>
                </p> -->

                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button" (click)="OnHideGelatoPopup()">BACK</a> 
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <div id="project-4" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <img class="img-fluid" src="assets/images/foodsDrinks/drink/1.jpg" alt="alternative">
                <!-- <p-galleria [(value)]="N1SRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="false" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria> -->
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Wines</h3>
                <hr class="line-heading">
                <h6>Imported Italian Wines</h6>
                <!-- <p>
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <li>TV</li>
                    <li>Fridge</li>
                    <li>Microwave</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (once a week)</li>
                    <li>Laundry (twice a week)</li>
                </p> -->

                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->
    <!-- end of project lightboxes -->

    