<header>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top navbar-custom"
       (scroll)="scrolling($event)" [ngClass]="{'top-nav-collapse': scroll || route !== 'Home'}"
       >

    <!-- Mobile Menu Toggle Button -->
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" 
        aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-awesome fas fa-bars"></span>
        <span class="navbar-toggler-awesome fas fa-times"></span>
    </button> -->
    <!-- end of mobile menu toggle button -->

    <div class="container">
      
      <!-- <a class="navbar-brand logo-image" href="index.html"><img src="assets/images/angelina logo.png" alt="alternative"></a> -->

      <!-- <a class="navbar-brand" [routerLink]="['/']">AngelinaBeachResort</a> -->
      <!-- Image Logo -->
      <div>
        <a class="navbar-brand" href="index.html">
          <img class="logo" 
          (scroll)="scrolling($event)" [ngClass]="{'logo-white': scroll || route !== 'Home'}"
          src="assets/images/AngelinaLogoBlack_NoBG.png" alt="alternative"> <!-- src="assets/images/AngelinaLogo_Transparent_12in.png"  -->
        </a>
      </div>
      <!-- <div class="text-center blink_me text-warning" style="z-index:9999;">THIS WEBSITE IS <br>STILL UNDER CONSTRUCTION!</div> -->
      
      <!-- <div style="background-color: #000;"> -->
        <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()"
              style="padding:0;">
          <!-- <span class="navbar-toggler-icon"></span> -->
          <span class="navbar-toggler-awesome fas fa-bars"></span>
          <span class="navbar-toggler-awesome fas fa-times"></span>
        </button>
      <!-- </div> -->
      
      <!-- data-target=".navbar-collapse"
      aria-label="Toggle navigation"
       -->

       <!-- d-sm-inline-flex -->
      <div class="navbar-collapse collapse flex-sm-row-reverse"
           [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow ml-auto"> <!-- style="font-size: 1rem;" -->
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/']">HOME</a>
          </li>
          
          <!-- nav-link page-scroll active -->
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/about-us']">ABOUT US</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/restaurant']">RESTAURANT</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/rooms']">ROOMS</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link" [routerLink]="['/contact-us']">CONTACT US</a>
          </li>

          <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link" [routerLink]="['/counter']">Counter</a>
          </li> -->
          <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link" [routerLink]="['/fetch-data']">SAMPLE FETCH DATA</a>
          </li> -->

          <li>
            <span class="nav-item social-icons">
              <span class="fa-stack">
                  <a style="cursor: pointer;" (click)="goToLink('https://web.facebook.com/angelinamalapascua')">
                      <span class="hexagon"></span>
                      <i class="fab fa-facebook-f fa-stack-1x" style="width: 2.5em;"></i>
                  </a>
              </span>
              <span class="nav-item">
                <span class="fa-stack">
                  <a style="cursor: pointer;" (click)="goToLink('https://www.instagram.com/angelinamalapascua')">
                    <span class="hexagon"></span>
                    <i class="fab fa-instagram fa-stack-1x" style="width: 2.5em;"></i>
                  </a>
                </span>
              </span>

              <!-- href="https://www.facebook.com/angelinabeachmalapascua" -->

              <!-- <span class="fa-stack">
                  <a href="#your-link">
                      <span class="hexagon"></span>
                      <i class="fab fa-twitter fa-stack-1x" style="width: 2.5em;"></i>
                  </a>
              </span> -->
            </span>
          </li>
        </ul>

        
      </div>
    </div>
  </nav>
</header>
