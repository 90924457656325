<!-- Rooms And Accomodations -->
<div id="projects" class="filter">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="section-title">ROOMS</div>
                <h4>Rooms And Accomodations</h4>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="row">
            <p class="testimonial-text text-justify">We also have 4 spacious rooms, 2 with sea view and 2 with garden view.
                The rooms are 42 s.q.m. they can accommodate from 2 to maximum 4 people.
                They are equipped with all comforts, refrigerator, air conditioning, safe, hot water and large balconies.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <!-- Filter -->
                <!--<div class="button-group filters-button-group">
                    <a class="button is-checked" data-filter="*"><span>SHOW ALL</span></a>
                    <a class="button" data-filter=".design"><span>DESIGN</span></a>
                    <a class="button" data-filter=".development"><span>DEVELOPMENT</span></a>
                    <a class="button" data-filter=".marketing"><span>MARKETING</span></a>
                    <a class="button" data-filter=".seo"><span>SEO</span></a>
                </div>--> <!-- end of button group -->
                <div class="grid d-flex justify-content-center">
                    <div class="element-item">
                        <div style="text-align: center;">
                            <h6><i>Deluxe Sea View</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img class="popup-with-move-anim" href="#project-1">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/rooms/SeaView/roomDeluxedN1_5x5.jpg" alt="alternative"></a>
                        </div>
                    </div>
                    
                    <div class="element-item">
                        <div style="text-align: center;">
                            <h6><i>Deluxe Garden View</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img2 class="popup-with-move-anim" href="#project-2">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/rooms/GardenView/roomDeluxeStudio_5x5.jpg" alt="alternative"></a>
                        </div>
                    </div>
                    
                    <div class="element-item">
                        <div style="text-align: center;">
                            <h6><i>Studio With Balcony</i></h6>
                        </div>
                        <div style="padding-top: 1rem;">
                            <a #img3 class="popup-with-move-anim" href="#project-3">
                                <div class="element-item-overlay"><span style="font-size: 15px;"><i>(See More)</i></span></div>
                                <img src="assets/images/rooms/StudioType/roomStudio_5x5.jpg" alt="alternative"></a>
                        </div>
                    </div>
                    
                    
                    <!-- 
                    
                    <div class="element-item design marketing">
                        <a class="popup-with-move-anim" href="#project-7"><div class="element-item-overlay"><span>Casual Wear</span></div><img src="assets/images/project-7.jpg" alt="alternative"></a>
                    </div>
                    <div class="element-item design marketing">
                        <a class="popup-with-move-anim" href="#project-8"><div class="element-item-overlay"><span>Zazoo Apps</span></div><img src="assets/images/project-8.jpg" alt="alternative"></a>
                    </div> -->
                </div> <!-- end of grid -->

                <!-- end of filter -->
                
            </div> <!-- end of col -->
            <!-- <div class="col-lg-12">
                <div class="grid">
                    <div class="element-item design development marketing">
                        <a #img4 class="popup-with-move-anim" href="#project-4"><div class="element-item-overlay"><span>Van Moose</span></div><img src="assets/images/project-4.jpg" alt="alternative"></a>
                    </div>
                    <div class="element-item design development marketing seo">
                        <a #img5 class="popup-with-move-anim" href="#project-5"><div class="element-item-overlay"><span>Joy Moments</span></div><img src="assets/images/project-5.jpg" alt="alternative"></a>
                    </div>
                    <div class="element-item design marketing seo">
                        <a #img6 class="popup-with-move-anim" href="#project-6"><div class="element-item-overlay"><span>Spark Events</span></div><img src="assets/images/project-6.jpg" alt="alternative"></a>
                    </div>
                </div>
            </div> -->
        </div> <!-- end of row -->

        <!-- <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '660px'}" [numVisible]="5"> 
            <ng-template pTemplate="item" let-item>
                <img [src]="item.previewImageSrc" style="width: 100%; display: block;" />
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="p-grid p-nogutter p-justify-center">
                    <img [src]="item.thumbnailImageSrc" />
                </div>
            </ng-template>
        </p-galleria> -->

        <div class="row" style="margin-top:3rem;">
            <div class="col-lg-12">
                <p-galleria [(value)]="allRoomsImages"  [responsiveOptions]="responsiveOptions"
                            [numVisible]="6"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="true" [showIndicators]="false" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="p-grid p-nogutter p-justify-center">
                            <img [src]="item.thumbnailImageSrc" />
                        </div>
                    </ng-template>
                </p-galleria>
            </div>
        </div>
    </div> <!-- end of container -->
</div> <!-- end of filter -->
<!-- end of projects -->


<!-- Project Lightboxes -->
    <!-- Lightbox -->
    <div id="project-1" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <!-- <img class="img-fluid" src="assets/images/project-2.jpg" alt="alternative"> -->
                <p-galleria [(value)]="N1DRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="true" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria>
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Deluxe</h3>
                <hr class="line-heading">
                <h6>Sea View</h6>
                <p>
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <!-- <li>TV</li> -->
                    <li>Fridge</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (daily)</li>
                </p>

                <!-- <div class="testimonial-container">
                    <p class="testimonial-text">Need a solid foundation for your business growth plans? Aria will help you manage sales and meet your current requirements.</p>
                    <p class="testimonial-author">General Manager</p>
                </div> -->
                <!-- <a class="btn-solid-reg" href="#your-link">DETAILS</a> <a class="btn-outline-reg mfp-close as-button" href="#projects">BACK</a>  -->
                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <div id="project-2" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <!-- <img class="img-fluid" src="assets/images/project-1.jpg" alt="alternative"> -->
                <p-galleria [(value)]="N2RoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="true" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria>
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Deluxe</h3>
                <hr class="line-heading">
                <h6>Garden View</h6>
                <p>This room is spacious that has 42 sq.meters.
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <!-- <li>TV</li> -->
                    <li>Fridge</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (daily)</li>
                </p>

                <!-- <div class="testimonial-container">
                    <p class="testimonial-text">Need a solid foundation for your business growth plans? Aria will help you manage sales and meet your current requirements.</p>
                    <p class="testimonial-author">General Manager</p>
                </div> -->
                <!-- <a class="btn-solid-reg" href="#your-link">DETAILS</a> <a class="btn-outline-reg mfp-close as-button" href="#projects">BACK</a>  -->
                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
                
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->

    <!-- Lightbox -->
    <div id="project-3" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-10">
                <!-- <img class="img-fluid" src="assets/images/project-3.jpg" alt="alternative"> -->
                <p-galleria [(value)]="N1SRoomsImages"  
                            [numVisible]="1"
                            [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" 
                            [showItemNavigatorsOnHover]="true"
                            [showIndicatorsOnItem]="false" 
                            [autoPlay]="true" [transitionInterval]="2000">
                    <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
                    </ng-template>
                </p-galleria>
            </div> <!-- end of col -->
            <div class="col-lg-2">
                <h3>Studio</h3>
                <hr class="line-heading">
                <h6>With Balcony</h6>
                <p>
                    Amenities and services inclusion:
                    <li>Aircon</li>
                    <!-- <li>TV</li> -->
                    <li>Fridge</li>
                    <li>Electric kettle</li>
                    <li>Hot/cold shower</li>
                    <li>Safetybox</li>
                    <li>Housekeeping (daily)</li>
                </p>

                <div>
                    <p class="testimonial-text text-justify">These rooms are situated 300 meters away from Angelina resort. Right behind the main beach, Bounty beach.</p>
                </div>
                <!-- <a class="btn-solid-reg" href="#your-link">DETAILS</a> <a class="btn-outline-reg mfp-close as-button" href="#projects">BACK</a>  -->
                <div class="row" style="float:right; padding-right: 20px"> 
                    <a style="padding: 1.1875rem 1.875rem 1.1875rem 1.875rem;
                    font: 700 0.75rem/0 Montserrat, sans-serif;" class="btn-outline-reg as-button mfp-close" type="button">BACK</a> 
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->
    <!-- end of project lightboxes -->

    