import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class EmailService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private apiURl: string = "/api/v1/contactus"; // "https://angelinabeachresort.com/api/v1/contactus"; //"http://localhost:81/api/v1/contactus"; //"/api/v1/contactus";
  constructor(private http: HttpClient, private toastService: ToastrService) { }

  pushToEmail(emailContent: any): Observable<any> {
    let body = JSON.stringify(emailContent);
    return this.http.post(this.apiURl, body, this.httpOptions)
    .pipe(catchError(this.handleError))
    }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}. ` +
        `Error Message: ${error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}