<!-- Contact -->
<div id="contact" class="form-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="text-container">
                    <div class="section-title">CONTACT US</div>
                    <h4>Get In Touch Using The Form</h4>
                    <p>Please feel free to contact us for any questions and inquiries</p>
                    <ul class="list-unstyled li-space-lg">
                        <li class="address"><i class="fas fa-map-marker-alt"></i>Malapascua Island, Daanbantayan, Cebu, Philippines 6013</li>
                        <li><i class="fas fa-phone"></i><a href="tel:+63 90512345678">+63 9665775853</a></li>
                        <!-- <li><i class="fas fa-phone"></i><a href="tel:+63 90512345678">+63 9178656217</a></li> -->
                        <li><i class="fas fa-envelope"></i><a href="mailto:angelina@gmail.com">Angelinabeach2008@gmail.com</a></li>
                    </ul>
                    <h4>Follow Us On Social Media</h4>

                    <span class="fa-stack">
                        <a style="cursor: pointer;" (click)="goToLink('https://web.facebook.com/angelinamalapascua')">
                            <span class="hexagon"></span>
                            <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </a>
                    </span>
                    <!-- <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-twitter fa-stack-1x"></i>
                        </a>
                    </span> -->
                    <!-- <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-pinterest fa-stack-1x"></i>
                        </a>
                    </span> -->
                    <span class="fa-stack">
                        <a style="cursor: pointer;" (click)="goToLink('https://www.instagram.com/angelinamalapascua')">
                            <span class="hexagon"></span>
                            <i class="fab fa-instagram fa-stack-1x"></i>
                        </a>
                    </span>
                    <!-- <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-linkedin-in fa-stack-1x"></i>
                        </a>
                    </span>
                    <span class="fa-stack">
                        <a href="#your-link">
                            <span class="hexagon"></span>
                            <i class="fab fa-behance fa-stack-1x"></i>
                        </a>
                    </span> -->
                </div> <!-- end of text-container -->
            </div> <!-- end of col -->
            <div class="col-lg-6">
                
                <!-- Contact Form -->
                <form #contactForm="ngForm" novalidate>
                    <div class="form-group">
                        <label class="label-control" for="cname">Name
                            <em *ngIf="contactForm.controls.cname?.invalid
                            && contactForm.controls.cname?.touched
                            && contactForm.controls.cname?.errors.required">*Required</em>
                        </label>
                        <input type="text" class="form-control-input" id="cname" 
                        minlength="1"  name="cname"
                        placeholder="E.g. John Smith" 
                        [(ngModel)]="Name" required>
                        
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <label class="label-control" for="cemail">Email
                            <em *ngIf="contactForm.controls.cemail?.invalid
                            && contactForm.controls.cemail?.touched
                            && contactForm.controls.cemail?.errors.required">*Required</em>
                        </label>
                        <input type="email" class="form-control-input" id="cemail" name="cemail" 
                        minlength="1" 
                        placeholder="E.g. johnsmith@yahoo.com"
                        [(ngModel)]="Email" required>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <label class="label-control" for="cmessage">Your message
                            <em *ngIf="contactForm.controls.cmessage?.invalid
                            && contactForm.controls.cmessage?.touched
                            && contactForm.controls.cmessage?.errors.required">*Required</em>
                        </label>
                        <textarea class="form-control-textarea" id="cmessage" name="cmessage" 
                        minlength="1" [(ngModel)]="Message" required placeholder="E.g. I would like to inquire..."></textarea>
                        <div class="help-block with-errors"></div>
                    </div>
                    <!-- <div class="form-group checkbox">
                        <input type="checkbox" id="cterms" name="cterms" [(ngModel)]="Terms" value="Agreed-to-Terms" required>I agree with Aria's stated <a href="privacy-policy.html">Privacy Policy</a> and <a href="terms-conditions.html">Terms Conditions</a> 
                        <div class="help-block with-errors"></div>
                    </div> -->
                    <!-- <div>
                        <button type="submit" class="form-control-submit-button" (click)="onSubmit(contactForm)" 
                        [disabled]="!(contactForm.valid)">SUBMIT MESSAGE</button>
                    </div> -->
                    <div class="pb-3">
                        <button type="submit" class="form-control-submit-button" (click)="onSubmit()" 
                        [disabled]="!(contactForm.valid)">SUBMIT MESSAGE</button>
                    </div>
                    <div class="form-message">
                        <div id="cmsgSubmit" class="h3 text-center hidden"></div>
                    </div>
                </form>

                <!-- <form id="contactForm" data-toggle="validator" data-focus="false">
                    <div class="form-group">
                        <input type="text" class="form-control-input" id="cname"  name="cname" [(ngModel)]="Name" required>
                        <label class="label-control" for="cname">Name</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control-input" id="cemail" name="cemail" [(ngModel)]="Email" required>
                        <label class="label-control" for="cemail">Email</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control-textarea" id="cmessage" name="cmessage" [(ngModel)]="Message" required></textarea>
                        <label class="label-control" for="cmessage">Your message</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="form-control-submit-button" (click)="onSubmit()">SUBMIT MESSAGE</button>
                    </div>
                    <div class="form-message">
                        <div id="cmsgSubmit" class="h3 text-center hidden"></div>
                    </div>
                </form> -->


                <!-- <form id="contactForm" data-toggle="validator" data-focus="false">
                    <div class="form-group">
                        <input type="text" class="form-control-input" id="cname" required>
                        <label class="label-control" for="cname">Name</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control-input" id="cemail" required>
                        <label class="label-control" for="cemail">Email</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control-textarea" id="cmessage" required></textarea>
                        <label class="label-control" for="cmessage">Your message</label>
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group checkbox">
                        <input type="checkbox" id="cterms" value="Agreed-to-Terms" required>I agree with Angelina's stated <a href="privacy-policy.html">Privacy Policy</a> and <a href="terms-conditions.html">Terms Conditions</a> 
                        <div class="help-block with-errors"></div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="form-control-submit-button">SUBMIT MESSAGE</button>
                    </div>
                    <div class="form-message">
                        <div id="cmsgSubmit" class="h3 text-center hidden"></div>
                    </div>
                </form> -->
                <!-- end of contact form -->

            </div> <!-- end of col -->
        </div> <!-- end of row -->
        <div class="card">
            <p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'500px'}" ></p-gmap>
        </div>
    </div> <!-- end of container -->
</div> <!-- end of form-2 -->
<!-- end of contact -->