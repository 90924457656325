import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  scroll: boolean
  route: string

  constructor(location: Location, router: Router) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path()
      }
      else {
        this.route = "Home";
      }
      // console.log('route: ' + this.route)
    })
  }

  ngOnInit() {
     window.addEventListener('scroll', this.scrolling, true)
  }
  scrolling = (s) => {
    if(this.route == "Home")
    {
      let sc = s.target.scrollingElement.scrollTop;
      // console.log('scrolling: ' + sc)
      if (sc >= 100) { this.scroll = true }
      else { this.scroll = false}
    }
    
  } 

  goToLink(url: string){
    window.open(url, "_blank");
  }

  /* Navbar Scripts */
  // jQuery to collapse the navbar on scroll
  //$(window).on('scroll load', function() {
  //  if ($(".navbar").offset().top > 20) {
  //    $(".fixed-top").addClass("top-nav-collapse");
  //  } else {
  //    $(".fixed-top").removeClass("top-nav-collapse");
  //  }
  //});
}
