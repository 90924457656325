import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GMapModule } from 'primeng/gmap';
import { Subject } from 'rxjs';
import { EmailService } from '../service/emailService';
declare var google: any; 

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
    options: any;
    overlays: any[];
    
    Name: string; Message: string; Email: string;

    constructor(private emailService: EmailService, private toastService: ToastrService) {
    }
    ngOnInit(): void {
        this.options = {
            center: {lat: 11.327607980811983, lng: 124.11281009344859}, //11.327607980811983, 124.11281009344859 //11.327613876290503, 124.11286140675703 //lat: 36.890257, lng: 30.707417
            zoom: 18
        };

        this.overlays = [
            new google.maps.Marker({position: {lat: 11.327607980811983, lng: 124.11281009344859}, title:"Angelina Beach Resort"}),
            new google.maps.Circle({center: {lat: 11.327607980811983, lng: 124.11281009344859}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 15})
        ];

        // this.overlays = [
        //     new google.maps.Marker({position: {lat: 36.879466, lng: 30.667648}, title:"Konyaalti"}),
        //     new google.maps.Marker({position: {lat: 36.883707, lng: 30.689216}, title:"Ataturk Park"}),
        //     new google.maps.Marker({position: {lat: 36.885233, lng: 30.702323}, title:"Oldtown"}),
        //     new google.maps.Polygon({paths: [
        //         {lat: 36.9177, lng: 30.7854},{lat: 36.8851, lng: 30.7802},{lat: 36.8829, lng: 30.8111},{lat: 36.9177, lng: 30.8159}
        //     ], strokeOpacity: 0.5, strokeWeight: 1,fillColor: '#1976D2', fillOpacity: 0.35
        //     }),
        //     new google.maps.Circle({center: {lat: 36.90707, lng: 30.56533}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500}),
        //     new google.maps.Polyline({path: [{lat: 36.86149, lng: 30.63743},{lat: 36.86341, lng: 30.72463}], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2})
        // ];
    }

    goToLink(url: string){
        window.open(url, "_blank");
    }

    onSubmit(): void{
        let model = {
            name: this.Name,
            message: this.Message,
            email: this.Email
        };
        this.emailService.pushToEmail(model).subscribe(result => {
            if (result == 1){
                this.Name = "";
                this.Message = "";
                this.Email = "";

                this.toastService.success('Inquiry has been submitted successfully','Success message');
            }
        }, error => {
            this.toastService.error('Your inquiry cannot be submitted at the moment!','Something went wrong');
        });
    }


    // private http: HttpClient
    // onSubmit(contactForm: NgForm) {
    //   if (contactForm.valid) {
    //     const email = contactForm.value;
    //     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //     this.http.post('https://formspree.io/f/mlearznd',
    //       { name: email.cname, replyto: email.cemail, message: email.cmessages },
    //       { 'headers': headers }).subscribe(
    //         response => {
    //           console.log(response);
    //         }
    //       );
    //   }
    // }

}

