import { viewClassName } from '@angular/compiler';
import { Component, OnInit, ViewEncapsulation, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { PhotoService } from '../../app/service/photoservice';
import * as $ from 'jquery';
import 'magnific-popup';

@Component({
    selector: 'app-rooms',
    templateUrl: './rooms.component.html'
})
export class RoomsComponent implements OnInit, AfterViewChecked {
    // @ViewChild('img') imgElement:ElementRef; 
    // @ViewChild('video') videoElement:ElementRef; 

    @ViewChild('img') imgElement: ElementRef
    @ViewChild('img2') imgElement2: ElementRef
    @ViewChild('img3') imgElement3: ElementRef
    // @ViewChild('img4') imgElement4: ElementRef
    // @ViewChild('img5') imgElement5: ElementRef
    // @ViewChild('img6') imgElement6: ElementRef
    
    N1DRoomsImages: any[];
    N1DImagesPath: string  = 'assets/data/n1DRooms.json';

    N1SRoomsImages: any[];
    N1SImagesPath: string  = 'assets/data/n1SRooms.json';

    N2RoomsImages: any[];
    N2ImagesPath: string  = 'assets/data/n2Rooms.json';

    allRoomsImages: any[];
    allImagesPath: string  = 'assets/data/allRooms.json';

    responsiveOptions:any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    constructor(private photoService: PhotoService) { }

    ngOnInit() {
        this.photoService.getImages(this.N1DImagesPath).then(images => {
            this.N1DRoomsImages = images
          })

        this.photoService.getImages(this.N1SImagesPath).then(images => {
            this.N1SRoomsImages = images
          })

        this.photoService.getImages(this.N2ImagesPath).then(images => {
          this.N2RoomsImages = images
        })

        this.photoService.getImages(this.allImagesPath).then(images => {
            this.allRoomsImages = images
          })
    }
    
    // onClick(): void{
    //     this.photoService.getImages(this.imagesPath).then(images => {
    //         this.roomsImages = images
    //       })
    // }
    ngAfterViewChecked (): void {
   
        if (this.imgElement) {
           $(this.imgElement.nativeElement).magnificPopup({ 
                type: 'inline',
                fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom'
            });
        }

        if (this.imgElement2) {
            $(this.imgElement2.nativeElement).magnificPopup({ 
                 type: 'inline',
                 fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                 fixedBgPos: true,
                 overflowY: 'auto',
                 closeBtnInside: true,
                 preloader: false,
                 midClick: true,
                 removalDelay: 300,
                 mainClass: 'my-mfp-slide-bottom'
             });
         }
         if (this.imgElement3) {
            $(this.imgElement3.nativeElement).magnificPopup({ 
                 type: 'inline',
                 fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
                 fixedBgPos: true,
                 overflowY: 'auto',
                 closeBtnInside: true,
                 preloader: false,
                 midClick: true,
                 removalDelay: 300,
                 mainClass: 'my-mfp-slide-bottom'
             });
         }
        //  if (this.imgElement4) {
        //     $(this.imgElement4.nativeElement).magnificPopup({ 
        //          type: 'inline',
        //          fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
        //          fixedBgPos: true,
        //          overflowY: 'auto',
        //          closeBtnInside: true,
        //          preloader: false,
        //          midClick: true,
        //          removalDelay: 300,
        //          mainClass: 'my-mfp-slide-bottom'
        //      });
        //  }
        //  if (this.imgElement5) {
        //     $(this.imgElement5.nativeElement).magnificPopup({ 
        //          type: 'inline',
        //          fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
        //          fixedBgPos: true,
        //          overflowY: 'auto',
        //          closeBtnInside: true,
        //          preloader: false,
        //          midClick: true,
        //          removalDelay: 300,
        //          mainClass: 'my-mfp-slide-bottom'
        //      });
        //  }
        //  if (this.imgElement6) {
        //     $(this.imgElement6.nativeElement).magnificPopup({ 
        //          type: 'inline',
        //          fixedContentPos: false, /* keep it false to avoid html tag shift with margin-right: 17px */
        //          fixedBgPos: true,
        //          overflowY: 'auto',
        //          closeBtnInside: true,
        //          preloader: false,
        //          midClick: true,
        //          removalDelay: 300,
        //          mainClass: 'my-mfp-slide-bottom'
        //      });
        //  }
    }
}

