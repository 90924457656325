import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PhotoService } from '../../app/service/photoservice';
// import {GalleriaModule} from 'primeng/galleria';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  images: any[];
  imagesPath: string  = 'assets/data/homeGallery.json';

  islandHopImages: any[];
  islandHopImagesPath: string = 'assets/data/islandHop.json';

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(private photoService: PhotoService) { }
  
  ngOnInit() {
    this.photoService.getImages(this.imagesPath).then(images => {
      this.images = images
    })

    this.photoService.getImages(this.islandHopImagesPath).then(images => {
      this.islandHopImages = images
    })
  }

}
