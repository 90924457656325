import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Image } from './image';

@Injectable()
export class PhotoService {

  constructor(private http: HttpClient) { }

  getImages(path: string) {
   return this.http.get<any>(path) /*'assets/data/photos.json'*/
     .toPromise()
     .then(res => <Image[]>res.data)
     .then(data => { return data; });
  }

  // allImages = [];

  // getImages() {
  //   return this.allImages = ImagesDetails.slice(0);
  // }
}

// const ImagesDetails = [
//   { "id": 1, "brand": "Image Brand", "url": "assets/images/gallery/b1.jpg" },
//   { "id": 2, "brand": "Image Brand", "url": "assets/images/gallery/b2.jpg" },

// ]
