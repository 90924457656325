<div>
  <!-- <div class="row">
    <div class="col-lg-12"> -->
      <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [numVisible]="1"
                  [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" [showItemNavigatorsOnHover]="true"
                  [showIndicatorsOnItem]="true" [autoPlay]="true" [transitionInterval]="2000">
        <ng-template pTemplate="item" let-item>
          <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;" />
        </ng-template>
        <!-- <ng-template pTemplate="thumbnail" let-item>
          <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item.thumbnailImageSrc" style="display: block;" />
          </div>
        </ng-template> -->
      </p-galleria>
    <!-- </div>
  </div> -->
</div>

<!-- [containerStyle]="{'margin-top':'2em'}" -->

<!-- Intro -->
<div id="intro" class="basic-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="text-container">
                  <div class="section-title">INTRO</div>
                  <h4>Angelina Pizzeria Restorante Italiano</h4>
                  <!-- <h6>We Serve Delicious Italian Foods, And Also Provides Comfortable Room Accommodations</h6> -->
                  <p class="text-justify">The pizzeria serves delicious pizza, made according to the best Italian traditions. 
                    The ingredients are imported from Italy and are served with a great choice of wines from the Veneto valleys. 
                    We have tables directly on the beach and a comfortable indoor dining area. We are open all year round from 8.00 am to 10.30 pm. We serve pizza from 6.00 pm onward.
                    <br>Now we also provide deluxe rooms accomodation.
                  </p>
                  <!-- <p class="testimonial-text">"Our mission is to get you through those tough moments relying on our team's expertise in starting and growing companies."</p>
                  <div class="testimonial-author">John Du - CEO</div> -->
              </div> <!-- end of text-container -->
          </div> <!-- end of col -->
          <div class="col-lg-3 d-flex justify-content-center">
              <div class="image-container pb-1">
                  <img class="img-fluid" src="/assets/images/collage_4x4_foods.jpg" alt="alternative">
              </div> <!-- end of image-container -->
          </div> <!-- end of col -->
          <div class="col-lg-3 d-flex justify-content-center">
            <div class="image-container">
              <img class="img-fluid" src="/assets/images/collage_4x4_rooms.jpg" alt="alternative">
            </div>
          </div>
      </div> <!-- end of row -->
  </div> <!-- end of container -->
</div> <!-- end of basic-1 -->
<!-- end of intro -->


<!-- Description -->
<div class="cards-1">
  <div class="container">
    <div class="section-title">THINGS YOU CAN DO AROUND MALAPASCUA</div><br>
    <div class="row">
        <div class="col">
            
            <!-- Card -->
            <div class="card">
                <span class="fa-stack">
                    <span class="hexagon"></span>
                    <!-- <i class="fas fa-chart-pie fa-stack-1x"></i> -->
                    <i class="fas fa-swimmer fa-stack-1x" style="text-align:left;padding-left:7px;"></i>
                    <i class="fas fa-umbrella-beach fa-stack-1x" style="text-align:left;padding-left:30px;"></i>
                </span>
                <div class="card-body">
                    <h4 class="card-title">Swimming & The Beach</h4>
                    <img class="w-100" style="border-radius: 0.5rem" src="assets/images/beach.jpg">
                    <p class="text-justify">With a wonderful white beach and crystal clear seawater, Malapascua Island is more than enough to satisfy your great vacation. </p>
                    <!-- <p class="text-justify">Island hopping is also another great option to hop around nearby islands by boat with their unique natural beauty.</p> -->
                </div>
            </div>
            <!-- end of card -->
            
            <!-- Card -->
            <div class="card">
                <span class="fa-stack">
                    <span class="hexagon"></span>
                    <!-- <i class="fas fa-list-alt fa-stack-1x"></i>  -->
                    <i class="fas fa-utensils fa-stack-1x" style="text-align:left;padding-left:10px;"></i>
                    <i class="fas fa-pizza-slice fa-stack-1x" style="text-align:left;padding-left:33px;"></i>
                </span>
                <div class="card-body">
                    <h4 class="card-title">Enjoy Great Foods</h4>
                    <img class="w-100" style="border-radius: 0.5rem" src="assets/images/enjoyFoods.jpg">
                    <p class="text-justify">Satisfy your stomach with delicious foods and beverages. Having a taste of our best Italian pizza is something  you can't forget.</p>
                </div>
            </div>
            <!-- end of card -->

            

        </div> <!-- end of col -->
    </div> <!-- end of row -->
    <div class="row">
      <div class="col">
        <!-- Card -->
          <div class="card">
            <span class="fa-stack">
                <span class="hexagon"></span>
                <!-- <i class="fas fa-binoculars fa-stack-1x"></i> -->
                <i class="fas fa-water fa-stack-1x" style="margin-top:-7px;"></i>
                <i class="fas fa-fish fa-stack-1x" style="vertical-align: top; padding-top:11px;"></i>
            </span>
            <div class="card-body">
                <h4 class="card-title">Scuba Diving</h4>
                <img class="w-100" style="border-radius: 0.5rem" src="assets/images/scubaThresher.jpg">
                <p class="text-justify">With it's abundance in marine bio diversity, you will be amazed by the wonders of unique species. 
                  And of course, diving with the thresher sharks will be another reminiscing moment.</p>
            </div>
          </div>
        <!-- end of card -->

        <!-- Card -->
        <div class="card">
          <span class="fa-stack">
              <span class="hexagon"></span>
              <!-- <i class="fas fa-chart-pie fa-stack-1x"></i> -->
              <!-- fas fa-swimmer fa-stack-1x -->
              <!-- <i class="fas fa-swimmer fa-stack-1x" style="text-align:left;padding-left:7px;"></i> -->
              <!-- <i class="fas fa-umbrella-beach fa-stack-1x" style="text-align:left;padding-left:30px;"></i> -->
              <i class="fas fa-ship fa-stack-1x" style="vertical-align: top; margin-top:-7px;"></i>
              <i class="fas fa-water fa-stack-1x" style="vertical-align: top; padding-top:10px;"></i>
          </span>
          <div class="card-body">
              <h4 class="card-title">Island Hopping</h4>
              <div>
                <p-galleria [(value)]="islandHopImages" [responsiveOptions]="responsiveOptions" [numVisible]="1"
                  [circular]="true" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" [showItemNavigatorsOnHover]="true"
                  [showIndicatorsOnItem]="true" >
                  <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%; max-height: 650px; display: block;border-radius: 0.5rem" />
                  </ng-template>
                </p-galleria>
              </div>
              <p class="text-justify">Island hopping is also another great option to hop around nearby islands by boat with their unique natural beauty.</p>
          </div>
          <!-- end of card -->
        </div>
      

      </div>
    </div>
  </div> <!-- end of container -->
</div> <!-- end of cards-1 -->
<!-- end of description -->