<!-- Footer -->
<div class="footer">
    <div class="container" style="max-width: 1500px;">
        <div class="row">
            <div class="col-md-4">
                <div class="text-container" style="text-align: center;">
                    <h4>Few Words About Angelina</h4>
                    <p>
                        We provide our guests authentic Italian food and affordable accommodations without compromise!
                        Since 2003.
                    </p>
                </div> <!-- end of text-container -->
            </div>
            <div class="col-md-4" style="text-align: center; padding-bottom: 2rem;">
                <div>
                    <img style="height: 10rem; border-radius: 0.5rem; padding-right: 4px;" src="assets/images/award_agoda22.jpg">
                    <img style="height: 10rem; border-radius: 0.5rem;" src="assets/images/award_booking22.png">
                </div>
                <!-- <div>
                    <img style="height: 5rem;" src="assets/images/award_booking22.png">
                </div> -->
                
            </div>
            <!-- <div class="col-md-2" style="text-align: center; padding-bottom: 2rem;">
                <img style="height: 5rem;" src="assets/images/award_booking22.jpg">
            </div> -->
            <div class="col-md-4">
                <div class="container" style="text-align: center;">
                    <h4>Angelina Beach Resort & Italian Restaurant Malapascua</h4>
                    <p>Malapascua Island, Daanbantayan, Cebu, Philippines 6013
                        AngelinaBeachResort.com<br>
                        Email: Angelinabeach2008@gmail.com
                    </p>
                </div>
            </div>

        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div> <!-- end of footer -->  
<!-- end of footer -->